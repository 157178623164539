import React, { useContext, useEffect, useState } from 'react'
import AccountBanner from '../../../../components/AccountBanner'
import { Button, Checkbox, CircularProgress, FormControlLabel, IconButton, TextField } from '@mui/material'
import AccountFooter from '../../../../components/AccountFooter'
import { useLocation, useNavigate } from 'react-router-dom'
import { getAppData } from '../functions/getAppData'
import { AuthContext } from '../../../../context/AuthContext'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../../../services/firebase/firebase'
import AccountLoading from '../../../../components/AccountLoading'
import getAppId from '../functions/getAppId'

export default function Password() {
    const { signIn, setSignIn, thirdPartyLogin, saveInBrowser, loginCookies, syncWithYuga } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [ password, setPassword ] = useState('');
    const [ visibility, setVisibility ] = useState('password');
    const [ saveAccount, setSaveAccount ] = useState(false);

    const [ data, setData ] = useState({});

    const [ submitting, setSubmitting ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    
    const [ isYuga, setIsYuga ] = useState(false);

    const [ error, setError ] = useState('');
    
    const handleSaveAccount = (event) => {
        setSaveAccount(event.target.checked);
    };

    const handlePassword = (e) => {
        e.preventDefault();
        setSubmitting(true);
        setSignIn({ ...signIn, password: password });

        const query = new URLSearchParams(window.location.search);
        const authType = query.get('authType');

        setTimeout(() => {
            signInWithEmailAndPassword(auth, signIn.email, password)
            .then((user) => {
                if (authType === 'sync') {
                    if (saveAccount) {
                        saveInBrowser(user.user, signIn.email, false, true);
                    }
                    syncWithYuga(user.user.uid)
                } else {
                    if (isYuga) {
                        if (saveAccount) {
                            saveInBrowser(user.user, signIn.email, true);
                        } else {
                            loginCookies(user.user);
                        }
                    } else {
                        thirdPartyLogin(user.user.uid)
                        // Verificar si la cuenta en esa app existe

                    }
                }
            })
            .catch((error) => {
                setSubmitting(false);
                console.log(error.code)
                setError(error.code);
            })
        }, 500)
    }

    const handleBack = () => {
        setSubmitting(true);
        setTimeout(() => {
            const newPath = location.pathname.replace('/signin/auth', '/signin');
            navigate(`${newPath}${location.search}`);
            setLoading(false);
        }, 500)
    }

    useEffect(() => {
        getAppId(setData, setLoading, setIsYuga);
    }, [])

    useEffect(() => {
        setError('')
    }, [password])

    useEffect(() => {
        if (!signIn.email) {
            setSubmitting(true);
            setTimeout(() => {
                handleBack();
            }, 1000)
        }
    }, [])

    if (loading) {
        return <AccountLoading />
    }

    return (
        <form 
            className={`account-inherit ${submitting && 'loading'}`}
            onSubmit={handlePassword}
        >
            <AccountBanner 
                submitting={submitting}
                isYuga={isYuga}
                brand={data}
                type="signin"
                title={"Autentícate"}
            >
                <div>
                    <TextField 
                        type={visibility}
                        className='full-width'
                        label="Contraseña"
                        autoFocus
                        style={{ marginBottom: '.5rem'}}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        autoComplete='password'
                        error={error !== ''}
                        InputProps={{
                            endAdornment: 
                                <IconButton 
                                    style={{ marginRight: '-.5rem'}}
                                    onClick={() => setVisibility(visibility === 'password' ? 'text' : 'password')}>
                                    {visibility === 'password' ? 
                                        <VisibilityOff />
                                    :
                                        <Visibility />
                                    }
                                    
                                </IconButton>
                        }}
                    />
                    <Button className='btn text-lite'>
                        <span>¿Olvidaste tu contraseña?</span>
                    </Button>
                    {error &&
                        <div style={{ marginTop: '1rem'}}>
                            <span className='font p-small variant-negative'>
                                {error === 'auth/invalid-credential' && 'El correo o la contraseña son incorrectos.'}
                                {error === 'auth/too-many-requests' && 'Intentalo de nuevo más tarde.'}
                                {error === 'auth/wrong-password' && 'La contraseña es incorrecta.'}
                                {error === 'auth/password-dont-match' && 'Las contraseñas no coinciden.'}
                                {error === 'auth/password-too-less' && 'La contraseña es demasiado corta.'}
                                {error === 'auth/password-too-long' && 'La contraseña es demasiado larga.'}
                                {error === 'auth/password-insecure' && 'La contraseña debe tener al menos una letra, un número y un carácter especial.'}
                                {error === 'auth/user-not-found' && 'El usuario no existe.'}
                                {error === 'auth/network-request-failed' && 'Error de conexión.'}
                                {error === 'auth/email-already-in-use' && 'Ese correo electrónico ya esta en uso.'}
                                {error === 'auth/weak-password' && 'La contraseña es muy frágil.'}
                                {error === 'auth/internal-error' && 'Tenemos un problema interno.'}
                                {error === 'auth/popup-closed-by-user' && 'Cerraste la ventana emergente.'}
                                {error === 'auth/invalid-email' && 'Correo inválido'}
                                {error === 'auth/only-premium' && 'Este contenido es exclusivo para miembros Premium.'}
                            </span>
                        </div>
                    }
                    <FormControlLabel 
                        checked={saveAccount} 
                        onChange={handleSaveAccount} 
                        style={{ 
                            margin: '1rem 0 0', 
                            display: 'flex',
                            alignItems: 'center'
                        }} 
                        control={
                            <Checkbox 
                                sx={{ 
                                    color: '#5a5a5a', 
                                    marginLeft: '-9px',
                                    marginRight: '-4.5px',
                                    marginTop: '-2px',
                                    '&.Mui-checked': { 
                                        color: '#000'
                                    } 
                                }} 
                            />} 
                        label="Guardar cuenta en el navegador." 
                    />
                    <AccountFooter 
                        brand={data}
                    />
                </div>
                <div className='buttons flex justify-between' style={{ margin: 'auto 0 0 -1rem', paddingTop: '2rem'}}>
                    <Button type='button' onClick={handleBack} className='btn light'>
                        <span>Volver</span>
                    </Button>
                    <Button type='submit' className='btn high'>
                        <span>Iniciar sesión</span>
                    </Button>
                </div>
            </AccountBanner>
        </form>
    )
}
